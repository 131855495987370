import { useState, useContext } from 'react';
import { SnackbarProvider } from 'notistack';
import { AuthContext } from 'features/auth/store/auth.provider';
import { useWindowSize } from 'features/misc/hooks/useWindowSize';

import Navbar from '../Navbar';
import NavbarMobile from '../NavbarMobile';
import UserBar from '../UserBar';

import './style.css';

const Layout = ({ children }) => {
  const auth = useContext<any>(AuthContext);
  const [open, setOpen] = useState(true);
  const { width, height } = useWindowSize();
  const navbar_name = open ? 'navbar-container' : 'navbar-container-closed';
  if(auth?.user?._id){
    return (
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right', }}>
        {
          width > 1200 ?
            <div className='main-container' style={{ maxWidth : width, maxHeight : height }}>
              <div className={navbar_name} style={{ minHeight : height, height : height }}>
                <Navbar open={open} setOpen={setOpen} />
              </div>
              <div style={{ flex : 2, backgroundColor : "#E9F4FC" }}>
                <UserBar user={auth.user} />
                <div className='global-view-container' style={{ minHeight : height-80+"px", height : height-80+"px" }}>
                  { children }
                </div>  
              </div>
            </div>
          :
            <div className='main-container' style={{  maxWidth : width, maxHeight : height}}>
              <NavbarMobile />
              <div style={{ flex : 2, backgroundColor : "#E9F4FC" }}>
                <UserBar user={auth.user} />
                <div className='global-view-container' style={{ minHeight : height-80+"px", height : height-80+"px" }}>
                  { children }
                </div>
              </div>
            </div>
        }
      </SnackbarProvider>
    )
  } else {
    return (
      <>
        { children }
      </>
    )
  }
}

export default Layout;
