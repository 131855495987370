import axios from 'axios';
import { url } from 'configs/url';

const url_fetch = url.site;

// const version = "v1";

const generic_callback_error = (error) => { console.error(error)}
// API To Call
export const API = {
	UPLOAD : "upload",
	WASTE : "waste",
	GARBAGE : "garbage",
	CONSIGNE : "consigne",
	HARDWARE : "hardware",
	GAME_CONFIG : "gameConfig",
	GAME : "game",
	MESSAGE : "message",
	NOTIFICATION : "notification",
	ROLE : "role",
	TIER : "tier",
	USER : "user",
	AUTH : "auth",

    // TO DELETE
	GROUP_HARDWARE : "hardwareGroup",

	CONTENT : "content",
	VIDEO : "video",
	LICENCE : "licence",
	TOKEN : "token",

    LANG : "lang",

    PERMISSION : "permission",
	ENTERPRISE : "enterprise",
}

// GET /api/{dataCall}/{action}/
export function actionGetAPI(dataCall, action, callback, callbackError = generic_callback_error) {
    fetch(url_fetch+'/api/'+dataCall+'/'+action+'/', { 
        method: 'GET'
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(error => {
        callbackError(error);
    });
}

// GET /api/{dataCall}/:id
export function getOneAPI(dataCall, id, callback, callbackError = generic_callback_error, params = null) {
    const url_params = params !== null ? "?" + new URLSearchParams(params).toString() : "";
    axios.get(url_fetch+'/api/'+dataCall+'/'+id+url_params)
        .then(res => { 
            callback(res.data) 
        }).catch(error => {
            callbackError(error);
        });
}

export function getOneActionAPI(dataCall, action, id, callback, callbackError = generic_callback_error, params = null) {
    const url_params = params !== null ? "?" + new URLSearchParams(params).toString() : "";
    axios.get(url_fetch+'/api/'+dataCall+'/'+action+"/"+id+url_params)
        .then(res => { 
            callback(res.data) 
        }).catch(error => {
            callbackError(error);
        });
}

// GET /api/{dataCall}/
export function getAllAPI(dataCall, callback, callbackError = generic_callback_error, params = null) {
    const url_params = params !== null ? "?" + new URLSearchParams(params).toString() : "";
    axios.get(url_fetch+'/api/'+dataCall+'/'+url_params)
        .then(res => {
            callback(res.data); 
        }).catch(error => {
            callbackError(error);
        });
}

// POST /api/{dataCall}/
export function createAPI(dataCall, data, callback, callbackError = generic_callback_error) {
    axios.post(url_fetch+'/api/'+dataCall+'/', data, {
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.status === 200) {
            return res;
        } else {
            callbackError(res);
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(error => {
        callbackError(error);
    });   
}

// POST /api/{dataCall}/list
export function postAPIToGetListID(dataCall, list = [], callback, callbackError = generic_callback_error) {
    axios.post(url_fetch+'/api/'+dataCall+'/list', list,  {
        headers: { 'Content-Type': 'application/json' }
    })
    .then(res => { return res; })
    .then(data => {
        callback(data);
    }).catch(error => {
        console.log(error);
        callbackError(error);
    });
}

export function updateAPI(dataCall, id, data, callback, callbackError = generic_callback_error) {
    // console.log(`${url_fetch}/api/${dataCall}/${id}`);
    axios.put(`${url_fetch}/api/${dataCall}/${id}`, data, {
        headers: { 'Content-Type': 'application/json' }
    }).then(response => {
        if (response.status === 200) {
            callback(response.data);
        } else {
            callbackError(response);
        }
    }).catch(error => {
        callbackError(error);
        // callbackError({error: error});
    });
}

// DELETE /api/{dataCall}/
export function deleteAPI(dataCall, id, callback, callbackError = generic_callback_error) {
    axios.delete(url_fetch+'/api/'+dataCall+'/'+id, {
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if (res.status === 200) {
            return res;
        } else {
            console.error(res);
        }
    }).then(data => {
        callback(data);
    }).catch(error => {
        callbackError(error);
    });   
}


// POST /api/{dataCall}/{action}/
export function actionPostAPI(dataCall, action, data, callback, callbackError = generic_callback_error) {
    // console.log(`${url_fetch}/api/${dataCall}/${action}/`);
    axios.post(`${url_fetch}/api/${dataCall}/${action}/`, JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' }
    }).then(response => {
        if (response.status === 200) {
            callback(response.data);
        } else {
            callbackError(response);
        }
    }).catch(error => {
        callbackError(error.response.data.message);
    }); 
}

// Delete /api/{dataCall}/{action}/
export function actionDeleteAPI(dataCall, action, callback, callbackError = generic_callback_error) {
    axios.delete(`${url_fetch}/api/${dataCall}/${action}/`)
    .then(response => {
        callback(response);
    }).catch(error => {
        callbackError(error);
    });
}

// POST /api/v1/upload/
export function actionUploadImage(file, callbackProgress, callback, callbackError = generic_callback_error) {
    const formData = new FormData();
    formData.append('file', file);
    axios.post(url_fetch+'/api/'+API.UPLOAD+'/', formData, {
        onUploadProgress : callbackProgress
    }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            console.error(res);
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(error => {
        callbackError(error);
    })
}