import { useContext } from 'react';
import { AuthContext } from '../store/auth.provider';

export const usePermissions = () => {
    const { user } = useContext<any>(AuthContext);

    const isRevertoEmployee = () => {
        return user?.enterprise?.name === "REVERTO";
    }

    const isAdmin = () => {
        return user?.roles._id === "5ee3a1df32e74d5f887babf0";
    }

    return {
        isRevertoEmployee,
        isAdmin
    };
}
